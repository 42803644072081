import React from 'react'

import Layout from '../components/Layout'

const sleep = ms => new Promise(res => setTimeout(res, ms))

export default class Contact extends React.Component {
  state = {
    username: '',
    password: '',
    error: null,
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      error: null,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    await sleep(500)
    this.setState({
      error: 'Unrecognized Login',
    })
  }

  render() {
    return (
      <Layout>
        <div className="leading-normal">
          <form name="contact" method="post" onSubmit={this.handleSubmit}>
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p className="hidden">
              <label>Don’t fill this out</label>
              <input name="bot-field" onChange={this.handleChange} />
            </p>

            <label className="block font-bold mb-2 text-xs uppercase">Username</label>
            <input
              className="appearance-none block bg-clouds mb-6 p-3 rounded-md text-grey-darker w-full"
              name="username"
              onChange={this.handleChange}
              placeholder="Your Username"
              required
              size="35"
              style={{ boxShadow: 'none' }}
              type="text"
            />
            <label className="block font-bold mb-2 text-xs uppercase">Password</label>
            <input
              className="appearance-none block bg-clouds mb-6 p-3 rounded-md text-grey-darker w-full"
              name="password"
              onChange={this.handleChange}
              placeholder="Your Password"
              required
              size="35"
              style={{ boxShadow: 'none' }}
              type="password"
            />
            <button
              className="bg-nephritis hover:bg-emerald font-normal px-6 py-3 rounded text-sm text-white"
              type="submit"
            >
              Login
            </button>
            {this.state.error === null
              ? null
              : <p className="text-red pt-4">{this.state.error}</p>
            }
          </form>
        </div>
      </Layout>
    )
  }
}
